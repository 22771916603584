import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex cp-flex--justify-between align-items-center cp-margin__b--small" }
const _hoisted_3 = { class: "cp-padding__r--8" }
const _hoisted_4 = { class: "cp-flex align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_form_button = _resolveComponent("form-button")!
  const _component_app_simple_checkbox = _resolveComponent("app-simple-checkbox")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_beginners_card = _resolveComponent("beginners-card")!
  const _component_modal_block = _resolveComponent("modal-block")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createVNode(_component_title_return, {
              icon: "task-sheet",
              "icon-size": "19",
              "is-return": false
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Задачи ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("form", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_form_multi_select, {
                filter: "",
                placeholder: "Не учитывать",
                modelValue: _ctx.inlineFilterSettings.geoRegion.checkedItems,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inlineFilterSettings.geoRegion.checkedItems) = $event)),
                label: _ctx.inlineFilterSettings.geoRegion.previewName,
                items: _ctx.inlineFilterSettings.geoRegion.list,
                "is-search-enabled": true,
                onUpdateItem: _ctx.selectRegion,
                class: "cp-admin-title__wrapper cp-admin-title__wrapper_margin-t-0 cp-width--216"
              }, null, 8, ["modelValue", "label", "items", "onUpdateItem"])
            ]),
            _createVNode(_component_app_header_main, {
              "filter-settings": _ctx.filterSettings,
              "is-to-add-hidden": true,
              onResetFilters: _ctx.resetFilters,
              onUpdateList: _ctx.updateList
            }, null, 8, ["filter-settings", "onResetFilters", "onUpdateList"]),
            _createVNode(_component_form_simple_switch, {
              "model-value": _ctx.inlineFilterSettings.viewed.list[0].checked,
              horizontal: true,
              label: "Просмотренные",
              class: "cp-admin-title__wrapper cp-margin__t--16 cp-margin__b-no cp-desc cp-margin__r--s",
              onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCheckboxFilter('viewed', $event)))
            }, null, 8, ["model-value"]),
            _createVNode(_component_gui_form_checkbox, {
              modelValue: _ctx.inlineFilterSettings.processed.list[0].checked,
              label: _ctx.inlineFilterSettings.processed.previewName,
              class: "cp-admin-title__wrapper cp-margin__b-no cp-desc cp-margin__r--s",
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateCheckboxFilter('processed', $event.target.checked)))
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small",
            style: _normalizeStyle({ height: 'calc(100vh - 110px)' }),
            "have-status": true,
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "is-hover": true,
            onChangePage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort,
            onTrackBy: _ctx.trackByItemId
          }, {
            "item-fullName": _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'employees_schedule', params: { employeeId: item.fullName.id } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.fullName.fullName), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Test"))
                  : _createCommentVNode("", true),
                _createVNode(_component_icon_font, {
                  class: "app-table-icon app-table-icon--active app-table-icon--row-hover cp-icon-hover cp-margin__l--3",
                  icon: "copy",
                  size: "16",
                  onClick: _withModifiers(($event: any) => (_ctx.copy(item)), ["stop"])
                }, null, 8, ["onClick"])
              ])
            ]),
            "item-phone": _withCtx(({ value, item }) => [
              value
                ? _withDirectives((_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value,
                    onPhoneClick: ($event: any) => (_ctx.makeCall(item.fullName.id))
                  }, null, 8, ["value", "onPhoneClick"])), [
                    [
                      _directive_tooltip,
                      'Телефон исполнителя',
                      void 0,
                      { right: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            "item-createdAt": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-to": "DD.MM.YYYY HH:mm:ss"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-action": _withCtx(({ item }) => [
              _withDirectives(_createVNode(_component_form_button, {
                text: "В работу",
                onClick: ($event: any) => (_ctx.openProcessedModal(item.action))
              }, null, 8, ["onClick"]), [
                [
                  _directive_tooltip,
                  'Взять в работу',
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            "item-employeeStatus": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.convertEmployeeStatus(item.employeeStatus)), 1)
            ]),
            "item-viewed": _withCtx(({ value, item }) => [
              _createVNode(_component_app_simple_checkbox, {
                "is-fill": true,
                "model-value": value,
                "true-value": true,
                "false-value": false,
                disabled: _ctx.disabledViewCheckboxes.includes(item.fullName?.id),
                onUpdate: ($event: any) => (_ctx.updateViewed(item.fullName?.id, $event))
              }, null, 8, ["model-value", "disabled", "onUpdate"])
            ]),
            _: 1
          }, 8, ["style", "headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount", "onSort", "onTrackBy"]))
        : _createCommentVNode("", true),
      (_ctx.showBeginnersModal)
        ? (_openBlock(), _createBlock(_component_modal_block, {
            key: 2,
            title: _ctx.modalTitle,
            class: "cp-modal__lg",
            onClick: _ctx.closeModal
          }, {
            default: _withCtx(() => [
              _createVNode(_component_beginners_card, { onCloseModal: _ctx.closeModal }, null, 8, ["onCloseModal"])
            ]),
            _: 1
          }, 8, ["title", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}