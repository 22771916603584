import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import inlineFilterModel from './inlineFilter';
import BeginnersInlineFilter from './inlineFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { getRegionsGeoAll } from '@/api/region';
import filterModel from './filter';
import BeginnersFilter from '@/store/tasks/beginners/filterEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import {
  getReactivationEmployeeStatuses,
  getReactivationList,
  getReactivationTaskStatuses,
  lockReactivationTask,
  readReactivationTask,
  releaseReactivationTask,
  updateReactivationTask,
} from '@/api/reactivation';
import { changeViewed } from '@/api/employees';
import { EmployeeViewType } from '@/lib/util/consts';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { getAllCompetenceList } from '@/api/competence';
import { getClients } from '@/api/client';
import { getSplitList } from '@/api/split';
import moment from 'moment';
import { ReactivationTask } from '@/interfaces/reactivation.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { convertObjectToQueryParams, dateFullFormat } from '@/lib/Utils';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'beginners';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class BeginnersModule extends PageBaseModule {
  filter: Filter;
  inlineFilter: Filter;
  model: ReactivationTask = {
    id: 0,
    comment: '',
    employeeStatus: '',
    lastContact: null,
    manager: null,
    nextContact: null,
    split: null,
    status: null,
    statusChangedAt: null,
    employee: {
      id: 0,
      fullName: '',
      phone: '',
      isTest: false,
      timezoneNumber: '',
    },
  };

  showBeginnersModal = false;

  taskStatuses: any = [];
  splitReasons: any = [];
  employeeStatuses: any = [];

  constructor(module: BeginnersModule) {
    super(module);

    const page = new PageEntity();
    this.pageSettings = page.values;
    page.values.actionPagination = 'beginners/updatePage';

    const filter = new filterModel();
    this.filter = getModule(BeginnersFilter);
    this.filter.setFilterName('beginnersFilter');
    this.filter.setTemplateClassName('template-lg');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
    this.filter.setBtnClassName('col-1-row-4');
    this.filter.setDefaultIndex(4);

    const inlineFilter = new inlineFilterModel();
    this.inlineFilter = getModule(BeginnersInlineFilter);
    this.inlineFilter.setFilterName('beginnersInlineFilter');
    this.inlineFilter.setFilterModel(inlineFilter.filterModel);
    this.inlineFilter.setFilterHandlers(inlineFilter.filterHandlers);
    this.inlineFilter.setDefaultIndex(0);
  }

  @Mutation
  SET_REGION(selected: any) {
    this.inlineFilter.updateMultiSelect(selected);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.beginnersSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_SHOW_BEGINNERS_MODAL(value: boolean) {
    this.showBeginnersModal = value;
  }

  @Mutation
  SET_TASK_STATUSES(list: any) {
    this.taskStatuses = list;
  }

  @Mutation
  SET_SPLIT_REASONS(list: any) {
    this.splitReasons = list;
  }

  @Mutation
  SET_EMPLOYEE_STATUSES(list: any) {
    this.employeeStatuses = list;
  }

  @Mutation
  SET_EMPLOYEE_VIEWED(payload: { id: string; value: boolean }) {
    const employees = (this.pageSettings.table as TableApiInterface).rows;
    const employeeForChange = employees.find((employee) => employee?.fullName?.id === payload.id);

    if (employeeForChange) {
      employeeForChange.viewed = payload.value;
    }
  }

  @Action({ rawError: true })
  async initRegionsSelect() {
    try {
      const geoRegions = await getRegionsGeoAll();

      return geoRegions.map((region: any) => {
        return {
          id: region.id,
          name: region.name,
          value: region.name,
        };
      });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async updateIsProcessedFilter(params: { key: string; checkbox: { bool: boolean; id: string; name: string } }) {
    await this.inlineFilter.updateCheckbox(params);
    this.inlineFilter.updateFilter();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();

      if (!this.filter.filterSettings.filter.includes('employeeStatuses')) {
        this.filter.filterSettings.filter += `&${convertObjectToQueryParams({
          [`filters[${this.filter.self.index}]`]: {
            id: 'employeeStatuses',
            value: ['beginner', 'beginner_calendar'],
          },
        })}`;
      }

      const filter = `${this.inlineFilter.filterSettings.filter}${this.filter.filterSettings.filter}`;

      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getReactivationList(this.pageSettings.pageCurrent, sort, filter, itemsQuery);
      await this.context.dispatch('setList', result.table);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);
    const tableInfo = uiTable
      .removeColumn('isTest')
      .removeColumn('currentTime')
      .removeColumn('lastContact')
      .removeColumn('manager')
      .removeColumn('nextContact')

      .setProperty('comment', 'width', '200px')
      .setProperty('createdAt', 'name', 'Создан')
      .setProperty('viewed', 'name', 'Просмотрено')
      .changeTitleOrder('comment', 5)
      .changeTitleOrder('action', 6)
      .changeTitleOrder('viewed', 7)

      .setSortableValues(sorts)
      .getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.beginnersSort ? JSON.parse(window.localStorage.beginnersSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.inlineFilter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async updateList() {
    await this.getList();
  }

  @Action({ rawError: true })
  async initCompetences() {
    try {
      const result: any = await getAllCompetenceList();

      return Object.values(result).map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          checked: false,
        };
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async initClientsSelect() {
    try {
      const result = await getClients();

      return Object.values(result.clients).map((client: any) => {
        return {
          id: client.id,
          value: client.name,
        };
      });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async updateClient(params: { key: string; value: { id: number; value: string } }) {
    if (params.value.id) {
      this.filter.resetMultiSelect('vacancy');
    }

    this.filter.updateSelect(params);
  }

  @Action({ rawError: true })
  resetClient(key: string) {
    this.filter.resetSelect(key);
  }

  @Action({ rawError: true })
  async initSplitSelect() {
    try {
      const result = await getSplitList();

      this.context.commit(
        'SET_SPLIT_REASONS',
        result.rows.map((item: any) => ({
          id: item.id,
          value: item.name,
        }))
      );

      return result.rows.map((split: any) => ({
        id: split.id,
        name: split.name,
        value: split.name,
      }));
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initEmployeeStatusesSelect() {
    try {
      const result = await getReactivationEmployeeStatuses();
      const mappedResult = result
        .map((item: any) => ({
          id: item.type,
          name: item.humanType,
          checked: false,
        }))
        .filter((item: { id: string }) => {
          return item.id === 'beginner' || item.id === 'beginner_calendar';
        });

      this.context.commit('SET_EMPLOYEE_STATUSES', mappedResult);

      return mappedResult;
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initTaskStatusesSelect() {
    try {
      const result = await getReactivationTaskStatuses();

      this.context.commit(
        'SET_TASK_STATUSES',
        result.map((item: any) => ({
          id: item.type,
          value: item.humanType,
        }))
      );
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async setShowBeginnersModal(value: boolean) {
    this.context.commit('SET_SHOW_BEGINNERS_MODAL', value);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async lockTask(id: string) {
    return await lockReactivationTask(id);
  }

  @Action({ rawError: true })
  async releaseTask() {
    return await releaseReactivationTask(this.model.id as any);
  }

  @Action({ rawError: true })
  async readTask(id: string) {
    try {
      readReactivationTask(id).then((result: any) => {
        this.model.id = result.id;
        this.model.comment = result.comment;
        this.model.employeeStatus = result.employeeStatus;
        this.model.lastContact = result.lastContact;
        this.model.manager = result.manager;
        this.model.split = result.split;
        this.model.status = result.status;
        this.model.statusChangedAt = result.statusChangedAt;
        this.model.employee.id = result.employee.id;
        this.model.employee.fullName =
          `${result.employee.lastName}` +
          (result.employee.firstName ? ` ${result.employee.firstName}` : '') +
          (result.employee.middleName ? ` ${result.employee.middleName}` : '') +
          ` ${moment(result.employee.birthday).format('DD.MM.YYYY')}`;
        this.model.employee.phone = result.employee.phone;
        this.model.employee.isTest = result.employee.isTest;
        this.model.employee.timezoneNumber = result.employee.timezoneNumber;

        this.model.nextContact = null;
        if (result.nextContact) {
          const date = dateFullFormat(result.nextContact).split(' ');
          const timeSplit = date[1].split(':');

          this.model.nextContact = {
            date: date[0],
            hour: timeSplit[0],
            minute: timeSplit[1],
            time: date[1],
          };
        }

        this.setShowBeginnersModal(true);
      });
    } catch (error: any) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async updateTask(data: any) {
    try {
      const result = await updateReactivationTask(this.model.id as any, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async updateViewed(params: { id: string; value: boolean }) {
    const rollbackChanges = () => this.SET_EMPLOYEE_VIEWED({ id: params.id, value: !params.value });

    try {
      this.SET_EMPLOYEE_VIEWED(params);
      const result = await changeViewed(params.id, params.value, EmployeeViewType.Newbies);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        rollbackChanges();
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      rollbackChanges();
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }
}

export default getModule(BeginnersModule);
