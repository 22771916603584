import FilterFactory from '@/lib/layouts/page/filterFactory';
import { phoneMask } from '@/lib/layouts/page/filterMasks';

export default class BeginnersFilter {
  filterHandlers = {
    update: 'beginnersFilter/updateFilter',
    reset: 'beginnersFilter/resetFilter',
    resetState: 'beginnersFilter/resetFilterState',
    removeSelected: 'beginnersFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setClassName('fullName', 'col-1-row-1');
    filter.setAction('fullName', 'beginnersFilter/updateSearch');

    filter.createSearchEntity('phone');
    filter.setPlaceholder('phone', 'По номеру телефона');
    filter.setName('phone', 'search_phone');
    filter.setPreviewName('phone', 'Тел');
    filter.setClassName('phone', 'col-1-row-2');
    filter.setIconClassName('phone', 'icon-phone');
    filter.setAction('phone', 'beginnersFilter/updateSearch');
    filter.setMask('phone', phoneMask);

    filter.createMultiSelectEntity('competences');
    filter.setTitle('competences', 'По компетенции');
    filter.setName('competences', 'multi_select_competences');
    filter.setPreviewName('competences', 'Компетенц');
    filter.setInitAction('competences', 'beginners/initCompetences');
    filter.setClassName('competences', 'col-1-row-3');
    filter.setIconClassName('competences', 'icon-book');
    filter.setAction('competences', 'beginnersFilter/updateMultiSelect');
    filter.setSearchEnabled('competences', true);

    filter.createSelectEntity('client');
    filter.setTitle('client', 'По клиенту');
    filter.setName('client', 'select_client');
    filter.setPreviewName('client', 'Клиент');
    filter.setClassName('client', 'col-2-row-1');
    filter.setIconClassName('client', 'icon-clients');
    filter.setAction('client', 'beginners/updateClient');
    filter.setInitAction('client', 'beginners/initClientsSelect');
    filter.setClearAction('client', 'beginners/resetClient');
    filter.setSearchEnabled('client', true);

    filter.createMultiSelectEntity('employeeStatuses');
    filter.setTitle('employeeStatuses', 'По статусу');
    filter.setName('employeeStatuses', 'multi_select_employeeStatuses');
    filter.setPreviewName('employeeStatuses', 'Статус');
    filter.setClassName('employeeStatuses', 'col-2-row-2');
    filter.setIconClassName('employeeStatuses', 'icon-status');
    filter.setAction('employeeStatuses', 'beginnersFilter/updateMultiSelect');
    filter.setInitAction('employeeStatuses', 'beginners/initEmployeeStatusesSelect');

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'beginnersFilter/updateCheckbox');
    filter.setClassName('isNotTest', 'col-2-row-3 cp-padding__t-4');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    this.filterModel = filter.filterModel;
  }
}
