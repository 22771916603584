import moment from 'moment';

export default {
  key: 'time',
  type: 'time-range',
  title: 'Дата, время звонка',
  placeholder: '',
  time: moment(new Date().setHours(new Date().getHours() + 2)).format('HH') + ':' + moment(new Date()).format('mm'),
  date: moment(new Date()).format('DD/MM/YYYY'),
  hour: moment(new Date().setHours(new Date().getHours() + 2)).format('HH'),
  minute: moment(new Date()).format('mm'),
  action: '/',
  required: true,
  start: 0,
  end: 24,
  step: 1,
};
