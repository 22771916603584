
import './simple-switch.scss';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormSimpleSwitch',
  emits: ['update:modelValue', 'update'],
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: { type: String },
    dataName: { type: String },
    trueValue: { type: [String, Number, Boolean], default: true },
    falseValue: { type: [String, Number, Boolean], default: false },
    error: { default: '', type: [String, Boolean] },
    label: { type: String },
    disabled: { type: Boolean },
    id: { type: String },
    textLeft: { type: Boolean },
    horizontal: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
    textHighlight: { type: Boolean, default: false },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  methods: {
    update() {
      this.$emit('update:modelValue', this.value);
      this.$emit('update', this.value);
    },
    prepareValue() {
      if (this.modelValue) {
        this.value = this.trueValue;

        return;
      }

      if (!this.modelValue) {
        this.value = this.falseValue;

        return;
      }
    },
  },
  created() {
    this.prepareValue();
    this.$emit('update:modelValue', this.value);
  },
  watch: {
    modelValue() {
      this.prepareValue();
      this.$emit('update:modelValue', this.value);
    },
  },
});
