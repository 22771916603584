/**
 * Global API for split.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from './requests';
import { FormData, FormResponse } from '@/interfaces/shared';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

/**
 * Get split list
 */
export const getSplitList = async (): Promise<TableApiInterface> => {
  const url = API.split.getList;
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get statistic split list
 *
 * @access CAN_READ_SPLIT_STATISTICS
 */
export const getSplitStatisticList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.split.getStatisticList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get statistic split list
 *
 * @access CAN_READ_SPLIT_STATISTICS
 */
export const getSplitStatisticAllList = async (
  sort: string,
  filter: string
): Promise<
  TableApiInterface<{
    name: string;
    percent: string;
  }>
> => {
  const assemblerUrl = new AssemblerUrl(API.split.getStatisticList);
  const url = assemblerUrl.addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get all split
 */
export const getSplitAll = async (): Promise<{ id: number; name: string; isArchived: boolean }[]> => {
  const url = API.split.getAll;
  const result = await makeGetRequest(url);

  return result.data.result.reasons;
};

/**
 * Add new Split
 *
 * @param data
 *
 * @access CAN_EDIT_SPLIT
 */
export const addNewSplit = async (data: FormData): Promise<FormResponse> => {
  const url = API.split.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Update split
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_SPLIT
 */
export const updateSplit = async (id: string, data: FormData): Promise<FormResponse<{ split: { id: number } }>> => {
  const url = API.split.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};
