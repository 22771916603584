import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { class: "text-phone" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["app-table-link", _ctx.className]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('phone-click')))
      }, [
        _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
        _createVNode(_component_icon_font, {
          class: "cp-padding__l-6",
          size: "14",
          icon: "phone"
        })
      ], 2)), [
        [_directive_tooltip, _ctx.tooltip]
      ])
    ]),
    _: 1
  }))
}