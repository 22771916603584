import { makeFormRequest, makeGetRequest } from '@/api/requests';
import API from '@/config/api-routers';

/**
 * Get reactivation list
 *
 * @param pageCurrent
 * @param sort
 * @param filter
 * @param pageAmountItems
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const getReactivationList = async (pageCurrent: number, sort: string, filter: string, pageAmountItems?: string) => {
  const url = API.reactivation.getList + `?page=${pageCurrent}&${pageAmountItems}${sort}${filter}`;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get reactivation employee statuses list
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const getReactivationEmployeeStatuses = async () => {
  const url = API.reactivation.getEmployeeStatuses;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get reactivation task statuses list
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const getReactivationTaskStatuses = async () => {
  const url = API.reactivation.getTaskStatuses;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Lock reactivation task
 *
 * @param id
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const lockReactivationTask = async (id: string) => {
  const url = API.reactivation.lockTask.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Read reactivation task
 *
 * @param id
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const readReactivationTask = async (id: string) => {
  const url = API.reactivation.readTask.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.reactivationTask;
};

/**
 * Release reactivation task
 *
 * @param id
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const releaseReactivationTask = async (id: string) => {
  const url = API.reactivation.releaseTask.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Update reactivation task
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_REACTIVATION_TASK
 */
export const updateReactivationTask = async (id: string, data: any) => {
  const url = API.reactivation.updateTask.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};
