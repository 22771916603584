export function makePhoneCall(phones: string[] | string, index?: number) {
  let phone = '';
  if (typeof phones === 'string') {
    phone = phones;
  } else {
    const localIndex = phones.length < (index ?? 0) + 1 ? 0 : index ?? 0;
    if (phones.length && phones[localIndex]) {
      phone = phones[localIndex];
    }
  }

  if (phone.length) {
    const phoneLink = document.createElement('a');
    phoneLink.href = 'tel:+' + phone;
    phoneLink.target = '_blank';
    phoneLink.style.display = 'none';
    document.body.append(phoneLink);
    phoneLink.click();
    setTimeout(() => {
      phoneLink.remove();
    });
  }
}
