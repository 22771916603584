
import './textPhone.scss';

import { defineComponent } from 'vue';
import IconFont from '@/components/icons/IconFont.vue';

export default defineComponent({
  name: 'TextPhone',
  emits: ['phone-click'],
  components: {
    IconFont,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
    tooltip: {
      type: String,
      default: 'Вызов',
    },
    className: {
      type: String,
      default: '',
    },
  },
});
